











import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {namespace} from "vuex-class"
import {StoreMutation} from "lib/types/vuex"

import {Message} from "store/communication/types"
import {Routing} from "store/routing/types"

import {IconEnvelope, IconEnvelopeOpened} from "custom/icons"

const routing = namespace("routing")

@Component({
	components: {
		IconEnvelope,
		IconEnvelopeOpened
	}
})
export default class MessageList extends Vue {
	@Prop({type: Array, required: true}) messages!: Array<Message>
	@routing.State selectedMessageId: Routing["selectedMessageId"]
	@routing.Mutation selectMessageId!: StoreMutation<string>

	selectMessage(message: Message) {
		this.selectMessageId(message.id)
	}
}
