import {Insurance, InsuranceState, Counselor} from "./types"
import {ConversionMap, ConversionFunction} from "lib/types/import"
import {one, many} from "lib/import/extract"
import {alphanumeric, datetime, numeric} from "lib/import/convert"

export const status: ConversionFunction<"Lopend" | "Expirerend"> = value => ["Lopend", "Expirerend"].includes(value) ? value : undefined

const required = true

const counselor: ConversionMap<Counselor> = {
	name: ["name", alphanumeric, required],
	phonenumber: ["phonenumber", alphanumeric, required],
	email: ["email", alphanumeric, required],
	website: ["website", alphanumeric, required],
	street: ["street", alphanumeric, required],
	housenumber: ["housenumber", numeric, required],
	zipcode: ["zipcode", alphanumeric, required],
	city: ["city", alphanumeric, required]
}

const insuranceItem: ConversionMap<Insurance> = {
	policyId: ["policyId", alphanumeric, required],
	product: ["product", alphanumeric, required],
	counselor: ["counselor", one(counselor), required],
	status: ["status", status, required],
	startDate: ["startDate", datetime, required],
	endDate: ["endDate", datetime, required],
	premiumPayment: ["premiumPayment", numeric, required],
	currentInsuranceValue: ["currentInsuranceValue", numeric, required]
}

const insurance: ConversionMap<InsuranceState> = {
	items: ["attributes.insurance.items", many(insuranceItem), required]
}

export const convert = one(insurance)
