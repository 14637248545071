import {cloneDeep} from "lodash-es"
import {storageOptions} from "lib/storage/session"
import persist from "lib/vuex/plugins/persist"
import Vue from "vue"
import Vuex, {MutationTree, Store} from "vuex"
import communication from "./communication/index"
import participant from "./participant/index"
import insurance from "./insurance/index"
import routing from "./routing/index"
import {RootState} from "./types"
import load from "./load"

Vue.use(Vuex)

const rootState: RootState = {
	language: "nl",
	timestamp: 0,
	lastLogin: null,
	logout: false,
	selectedMessageId: undefined
}

const mutations: MutationTree<RootState> = {
	timestamp(state) {
		state.timestamp = Date.now()
	},
	logout(state) {
		state.logout = true
	},
	selectMessage(state, id?: string) {
		state.selectedMessageId = id
	}
}

const plugins = process.env.SERVER ? [] : [
	persist({
		...storageOptions,
		blacklist: ["text"],
		deactivate: s => s.state.expired
	}),
	load
]

const store = new Store<RootState>({
	modules: {
		participant,
		communication,
		insurance,
		routing
	},
	getters: {
		selectedMessageId: state => state.selectedMessageId
	},
	mutations,
	plugins,
	state: cloneDeep(rootState),
	strict: process.env.NODE_ENV !== "production"
})

export default store
