import {Module} from "vuex"
import {RootState} from "../types"
import { Routing } from "./types"

const routingState: Routing = {
	selectedMessageId: undefined,
	selectedPolicyId: undefined,
	selectedDocumentType: undefined,
	selectedProfileItem: undefined
}

const routing: Module<Routing, RootState> = {
	actions: {
	},
	getters: {
	},
	mutations: {
		selectMessageId: (state, payload: string | undefined) => {
			state.selectedMessageId = payload
		},
		selectPolicyId: (state, payload: string) => {
			state.selectedPolicyId = payload
		},
		selectDocumentType: (state, payload: string) => {
			state.selectedDocumentType = payload
		},
		selectProfileItem: (state, payload: string) => {
			state.selectedProfileItem = payload
		}
	},
	namespaced: true,
	state: routingState
}

export default routing
