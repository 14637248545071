import {Module} from "vuex"
import diff, {Diff} from "lib/date/diff"
import {RootState} from "../types"
import {Communication, Document, Message} from "./types"
import {participantURL, documentDownloadURL} from "../settings"
import {post} from "../request"

const communicationState: Communication = {
	messages: [],
	documents: []
}

const markMessage = post(`${participantURL}markmessageread/`)
const dateDiffMillis = <T extends { date: Date }>(a: T, b: T) => diff(Diff.Millis, a.date, b.date)

const communication: Module<Communication, RootState> = {
	actions: {
		async messageRead(context, payload: Message) {
			if (!payload.read) {
				await markMessage({messageID: payload.id})
				context.commit("messageRead", payload)
			}
		},
		downloadDocument: (context, payload: Document) => {
			context.commit("documentDownloaded", payload)
			// TODO: this is error prone. If the document doesn't exist, then we leave the application and get a 404 or worse.
			// Should be solved using a link with target blank.
			window.open(documentDownloadURL(payload.id), "_blank")
		}
	},
	getters: {
		communication: state => state,
		unreadMessages: state => state.messages.reduce((count, message) => count + (!message.read ? 1 : 0), 0),
		unreadDocuments: state => state.documents.reduce((count, document) => count + (!document.downloaded ? 1 : 0), 0),
		messages: state => [...state.messages].sort(dateDiffMillis),
		documents: state => [...state.documents].sort(dateDiffMillis),
		hasUPOUitDienst: state => state.documents.some(document => document.type === "UPOUitDienst" && diff(Diff.Months, document.date, new Date()) < 6),
		startedEmploymentLast6Months: (_state, _getters, rootState) =>
			rootState.participant!.newEmployeeDate ? diff(Diff.Months, rootState.participant!.newEmployeeDate!, new Date()) < 6 : false
	},
	mutations: {
		hydrate(state, data: Communication) {
			Object.assign(state, data)
		},
		messageRead(state, message: Message) {
			if (state.messages.includes(message)) {
				message.read = true
			}
		},
		documentDownloaded(state, document: Document) {
			if (state.documents.includes(document)) {
				document.downloaded = true
			}
		}
	},
	namespaced: true,
	state: communicationState
}

export default communication
