import {FieldRules} from "lib/types/validation"
// import {iban} from "lib/validation/local/nl"
import {email, iban} from "lib/validation/formats"
import {Person, MeubelParticipant} from "./types"

const personRules: FieldRules<Person> = {
	iban: {
		type: "string",
		required: false,
		valid: iban
	}
}

const participantRules: FieldRules<MeubelParticipant> = {
	email: {
		type: "string",
		required: true,
		valid: email
	},
	...personRules
}

export default participantRules
