import {RootState} from "../types"
import {InsuranceState} from "./types"
import {Module, GetterTree} from "vuex"

const getters: GetterTree<InsuranceState, any> = {
	items: state => state.items
}

const insurance: Module<InsuranceState, RootState> = {
	actions: {},
	getters,
	mutations: {
		hydrate(state, data: InsuranceState) {
			Object.assign(state, data)
		}
	},
	namespaced: true,
	state: {
		items: []
	}
}

export default insurance
