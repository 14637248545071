import {Module} from "vuex"
import {RootState} from "../types"
import {post} from "../request"
import {participantURL} from "../settings"
import {MeubelParticipant, Employment} from "./types"
import diff, {Diff} from "lib/date/diff"
import {Gender, Status, MaritalStatus} from "lib/types/personal"
import validationActions from "lib/vuex/validationActions"
import {ActionTree} from "vuex"
import validationErrors from "../request/validationErrors"
import participantRules from "./validation"
import {Submit} from "lib/types/request"

const participantState: MeubelParticipant = {
	aowDate: new Date(0),
	aowAge: 0,
	addresses: [],
	birthDate: new Date(0),
	citizenServiceNumber: "",
	email: undefined,
	gender: Gender.Unknown,
	iban: undefined,
	initials: undefined,
	firstName: undefined,
	lastName: "",
	maritalStatus: MaritalStatus.Unknown,
	phoneNumbers: [],
	prefix: undefined,
	partners: [],
	relationNumber: "",
	status: Status.Unknown,
	newEmployeeDate: undefined,
	hasOpKoersBasisAccess: {
		reason: "too-young",
		result: false
	},
	hasOpKoersPlannerAccess: {
		reason: "too-young",
		result: false
	},
	employment: {
		salary: 0,
		parttimePercentage: 0,
		employers: []
	},
	totalFinalAmount: 0
}

const actions = <T extends MeubelParticipant, R>(submit: Submit): ActionTree<T, R> =>
	validationActions(participantRules, submit, validationErrors)

const participant: Module<MeubelParticipant, RootState> = {
	actions: {
		...actions(post(participantURL))
	},
	getters: {
		age: state => diff(Diff.Months, state.birthDate, new Date()) / 12,
		address: state => state.addresses[0],
		phone: state => state.phoneNumbers[0] && state.phoneNumbers[0].number,
		partner: state => state.partners[0]
	},
	mutations: {
		hydrate(state, data: MeubelParticipant) {
			data.aowAge = diff(Diff.Months, data.birthDate, data.aowDate) / 12
			Object.assign(state, data)
		},
		email(state, value: string) {
			state.email = value
		},
		iban(state, value: string) {
			state.iban = value
		},
		employment: (state, employment: Employment) => {
			state.employment = employment
		},
		mutateEmail: (state, value: string) => {
			state.email = value
		}
	},
	namespaced: true,
	state: participantState
}

export default participant
