import Vue, { DirectiveOptions, VNode, VNodeDirective } from "vue"
import checkTreeUp from "lib/dom/checkTreeUp"
import always from "lib/function/always"

export interface SelectableData {
	class: string
	domEvent: keyof HTMLElementEventMap
	trigger?: string
	selectEvent: string
	deselectEvent: string
	deselectable?: boolean
	selectable: (el: Element) => boolean
}

const emit = (parentVNode: VNode, event: string | undefined, child: Element) => {
	const emitter: Vue | undefined = parentVNode.componentInstance
	if (emitter && event) {
		// The 'checkTreeUp' function is used for when the VNode is encapsulated by an HTMLElement.
		// It checks if the emitterchild is a child of the child variable.
		// emitter.$children only shows VNode instances, and not the top HTMLElements.
		const childVNode = emitter.$children.find(c => checkTreeUp(child, c.$el))
		emitter.$emit(event, childVNode)
	}
}

const bindingData = (binding: VNodeDirective): SelectableData => {
	const data: SelectableData = {
		class: "selected",
		domEvent: "click",
		selectEvent: "select",
		deselectEvent: "deselect",
		deselectable: false,
		selectable: always
	}
	if (binding.value) {
		Object.assign(data, binding.value)
	}
	return data
}

const selectOne = (parentVNode: VNode, data: SelectableData, child?: Element) => {
	const currentChild = [...(parentVNode.elm as HTMLElement).children].find(c => c.classList.contains(data.class))
	const selectChild = child || currentChild || null
	// check if child does not have the 'not_selectable_class' class
	if (selectChild && data.selectable(selectChild)) { // select child if selectable and not selected
		if (currentChild !== selectChild) {
			selectChild.classList.add(data.class)
			if (currentChild) {
				currentChild.classList.remove(data.class) // deselect current selected child
			}
			emit(parentVNode, data.selectEvent, selectChild)
		} else if (data.deselectable) {
			currentChild.classList.remove(data.class) // deselect current selected child
			emit(parentVNode, data.deselectEvent, selectChild)
		}
	}
}

const selectMultiple = (parentVNode: VNode, data: SelectableData, child?: Element) => {
	if (child && data.selectable(child)) {
		if (child.classList.contains(data.class)) { // deselect child if already selected
			child.classList.remove(data.class)
			emit(parentVNode, data.deselectEvent, child)
		} else { // select child
			child.classList.add(data.class)
			emit(parentVNode, data.selectEvent, child)
		}
	}
}

const options = (selectBehavior: (parentVNode: VNode, binding: SelectableData, child?: Element) => void): DirectiveOptions => {
	return {
		inserted: (el, binding, vnode) => {
			const data = bindingData(binding)
			el.addEventListener(data.domEvent, e => {
				const t = e.target as HTMLElement // clicked element
				let triggered: boolean = true // stays true if no trigger selected

				// you can specify a trigger instead of having the whole element as a trigger
				// this is where that trigger, if selected, gets evaluated
				if (data.trigger) {
					// Checks if the clicked element is inside of the trigger
					triggered = checkTreeUp(el, t, Infinity, (_a, b) => {
						return !!b.classList.contains(data.trigger!.toLowerCase())
					})
				}

				// Selects the child needed to be changed
				if (triggered) {
					let target: Element | null = null // Checking which child is selected
					for (const child of el.children) {
						if (checkTreeUp(child, t)) {
							target = child
							break
						}
					}
					if (target) {
						selectBehavior(vnode, data, target)
					}
				}
			})
		},
		componentUpdated: (_el, binding, vnode) => {
			Vue.nextTick(() => {
				selectBehavior(vnode, bindingData(binding), undefined)
			})
		}
	}
}

export const single = options(selectOne)
export const multiple = options(selectMultiple)
