






















import Vue from "vue"
import Component from "vue-class-component"
import {Mutation, namespace, State} from "vuex-class"

import {IconAsrLogo} from "custom/icons"

import {MeubelParticipant} from "store/participant/types"

const communication = namespace("communication")

@Component({
	components: {

		// icons
		IconAsrLogo
	}
})
export default class PageHeader extends Vue {
	@Mutation logout!: () => void

	@State participant!: MeubelParticipant

	@communication.Getter unreadMessages!: number
	@communication.Getter unreadDocuments!: number

	menuModalOpened = false

	constructUrl(url: string) {
		return !process.env.SERVER
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}
}
