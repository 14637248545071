import checkmark from "assets/svg/checkmark.svg?inline"
import chevron from "assets/svg/chevron.svg?inline"
import close from "assets/svg/default_close.svg?inline"
import document from "assets/svg/default_document.svg?inline"
import folder from "assets/svg/folder.svg?inline"
import graph from "assets/svg/stock-data-graph.svg?inline"
import pencil from "assets/svg/edit.svg?inline"
import envelope from "assets/svg/mail.svg?inline"
import envelopeOpened from "assets/svg/email-read-o.svg?inline"
import envelopeFilled from "assets/svg/default_envelope-filled.svg?inline"
import mapsFilled from "assets/svg/icon_maps-filled.svg?inline"
import person from "assets/svg/person.svg?inline"
import personFilled from "assets/svg/icoon_profiel-filled.svg?inline"
import repeat from "assets/svg/icoon_repeat.svg?inline"
import send from "assets/svg/icoon_send_static.svg?inline"
import search from "assets/svg/search.svg?inline"
import star from "assets/svg/star.svg?inline"
import suitcase from "assets/svg/default_suitcase.svg?inline"
import trashcan from "assets/svg/trash-o.svg?inline"
import piggybank from "assets/svg/precaution.svg?inline"
import pensiondetails from "assets/svg/icoon_pensioengetallen.svg?inline"
import defensiveRisk from "assets/svg/animation/defensive.svg?inline"
import neutralRisk from "assets/svg/animation/neutral.svg?inline"
import offensiveRisk from "assets/svg/animation/offensive.svg?inline"
import footer from "assets/svg/footer-icon.svg?inline"
import arrow from "assets/svg/arrow.svg?inline"
import asrLogo from "assets/svg/asrlogo.svg?inline"
import upload from "assets/svg/icoon_upload.svg?inline"

// tslint:disable:variable-name
export const IconArrow = arrow
export const IconCheckmark = checkmark
export const IconChevron = chevron
export const IconClose = close
export const IconDocument = document
export const IconEnvelope = envelope
export const IconEnvelopeOpened = envelopeOpened
export const IconEnvelopeFilled = envelopeFilled
export const IconGraph = graph
export const IconMapsFilled = mapsFilled
export const IconPerson = person
export const IconPersonFilled = personFilled
export const IconRepeat = repeat
export const IconSend = send
export const IconSearch = search
export const IconStar = star
export const IconSuitcase = suitcase
export const IconTrashcan = trashcan
export const IconPencil = pencil
export const IconFolder = folder
export const IconPiggybank = piggybank
export const IconPensiondetails = pensiondetails
export const IconDefensiveRisk = defensiveRisk
export const IconNeutralRisk = neutralRisk
export const IconOffensiveRisk = offensiveRisk
export const IconFooter = footer
export const IconAsrLogo = asrLogo
export const IconUpload = upload
