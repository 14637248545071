


















































import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {State, namespace} from "vuex-class"

import {StoreMutation} from "lib/types/vuex"

import PageHeader from "custom/PageHeader.vue"
import PageFooter from "custom/PageFooter.vue"
import { IconFooter } from "custom/icons"
import LinkButton from "components/button/LinkButton.vue"
import {IconArrow} from "custom/icons"

const routing = namespace("routing")

@Component({
	components: {
		PageHeader,
		PageFooter,
		IconFooter,
		LinkButton,
		IconArrow
	}
})
export default class Page extends Vue {
	@State expired!: boolean
	@Prop({type: String, required: false }) contentPage!: string
	@Prop({type: String, required: false }) backLink!: string
	@Prop({type: Boolean, required: false }) messageSelected!: boolean

	@routing.Mutation selectMessageId!: StoreMutation<string | undefined>

	hasTitle = false
	hasSubTitle = false
	hasNavigation = false

	mounted() {
		this.hasTitle = !!this.$slots.title
		this.hasSubTitle = !!this.$slots.subtitle
		this.hasNavigation = !!this.$slots.navigation
	}

	constructBackLink() {
		return this.messageSelected ? this.selectMessageId(undefined) : window.location.href = this.backLink
	}
}
