import {ConversionMap} from "lib/types/import"
import {one, many} from "lib/import/extract"
import {datetime, bool, alphanumeric, date, numeric} from "lib/import/convert"
import {gender, maritalStatus, phoneType, status} from "../import/convert"
import {MeubelParticipant, Access} from "./types"
import {Address, Participant, Partner, Person, PhoneNumber} from "./types"

const required = true
const optional = false
const reasons = [
	"too-young",
	"too-old",
	"REASONPENSIONER",
	"REASONPARTIALLYPENSIONED",
	"REASONPARTIALAO",
	"REASONMULTIPLEACTIVEPLANS",
	"REASONDISPENSATION",
	"REASONREGRET",
	"REASONNOTACTIVE",
	"none"
]

const address: ConversionMap<Address> = {
	city: ["attributes.city", alphanumeric, optional],
	country: ["attributes.countryName", alphanumeric, required],
	extra: ["attributes.extra", alphanumeric, optional],
	number: ["attributes.nr", alphanumeric, optional],
	add: ["attributes.add", alphanumeric, optional],
	street: ["attributes.street", alphanumeric, required],
	zipcode: ["attributes.zipcode", alphanumeric, optional],
	state: ["attributes.state", alphanumeric, optional],
	lastChanged: ["attributes.lastChangedDate", date, optional]
}

const phoneNumber: ConversionMap<PhoneNumber> = {
	type: ["attributes.type", phoneType, required],
	number: ["attributes.nr", alphanumeric, required]
}

const person: ConversionMap<Person> = {
	birthDate: ["attributes.birthdate", date, required],
	citizenServiceNumber: ["attributes.citizenservicenumber", alphanumeric, required],
	firstName: ["attributes.firstname", alphanumeric, optional],
	gender: ["attributes.gender", gender, required],
	iban: ["attributes.IBAN", alphanumeric, optional],
	initials: ["attributes.initials", alphanumeric, optional],
	lastName: ["attributes.lastname", alphanumeric, required],
	prefix: ["attributes.prefix", alphanumeric, optional],
	relationNumber: ["attributes.relationnumber", alphanumeric, required]
}

const partner: ConversionMap<Partner> = {
	maritalStatus: ["attributes.maritalstatus", maritalStatus, required],
	maritalDate: ["attributes.maritaldate", date, optional],
	divorceDate: ["attributes.divorcedate", date, optional],
	...person
}

const participantMap: ConversionMap<Participant> = {
	aowAge: ["attributes.aowAge", numeric, required],
	email: ["attributes.email", alphanumeric, optional],
	addresses: ["attributes.addresses", many(address), required],
	phoneNumbers: ["attributes.phonenumbers", many(phoneNumber), required],
	partners: ["attributes.partners", many(partner), required],
	status: ["attributes.status", status, required],
	...person
}

const opkoersReason = (value: any) => {
	if (reasons.includes(value)) {
		return value
	} else {
		return undefined
	}
}

const access: ConversionMap<Access> = {
	result: ["result", bool, required],
	reason: ["reason", opkoersReason, required]
}

const participant: ConversionMap<MeubelParticipant> = {
	...participantMap,
	maritalStatus: ["attributes.maritalstatus", maritalStatus, required],
	aowDate: ["attributes.aowDate", datetime, required],
	aowAge: ["nonexistent", () => 0, required], // Override default: we receive aow date. Calculate on hydrate.
	newEmployeeDate: ["attributes.extendedattributes.newemployeedatetime", datetime, optional],
	hasOpKoersBasisAccess: ["attributes.extendedattributes.hasOpKoersBasisAcces", one(access), required],
	hasOpKoersPlannerAccess: ["attributes.extendedattributes.hasOpKoersPlannerAcces", one(access), required],
	totalFinalAmount: ["attributes.extendedattributes.totalFinalAmount", numeric, required],
	employment: ["nonexistent", () => ({salary: 0, parttimePercentage: 0, employers: []}), required]
}

export const convert = one(participant)
