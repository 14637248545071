












import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {namespace} from "vuex-class"
import {StoreAction} from "lib/types/vuex"

import {Routing} from "store/routing/types"
import {Message} from "store/communication/types"

import MessageList from "custom/MessageList.vue"

const communication = namespace("communication")
const routing = namespace("routing")

@Component({
	components: {
		MessageList
	}
})
export default class MessageBox extends Vue {
	@Prop({type: Array, required: true}) messages!: Array<Message>
	@routing.State selectedMessageId: Routing["selectedMessageId"]
	@communication.Action messageRead!: StoreAction<Message>

	async selectMessage(message: Message) {
		this.$store.commit("selectMessage", message.id)
		await this.messageRead(message)
	}

	get selectedMessage() {
		return this.selectedMessageId ? this.messages.find(x => x.id === this.selectedMessageId) : undefined
	}

}
