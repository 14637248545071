




























import Vue from "vue"
import Component from "vue-class-component"
import {State} from "vuex-class"

import {
	IconArrow,
	IconMapsFilled,
	IconStar
} from "custom/icons"
import IconLabel from "custom/IconLabel.vue"

import Card from "components/panel/Card.vue"
import LinkButton from "components/button/LinkButton.vue"

import {MeubelParticipant} from "store/participant/types"

@Component({
	components: {
		Card,
		IconLabel,
		LinkButton,

		IconArrow,
		IconMapsFilled,
		IconStar
	}
})
export default class PageFooter extends Vue {
	@State participant!: MeubelParticipant

	constructUrl(url: string) {
		return !process.env.SERVER
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}
}
