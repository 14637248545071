import {locale, plural} from "lib/i18n/locale/nl_NL"
import {Options} from "lib/types/i18n"

const options: Options = {
	locale,
	plural,
	texts: {}
}

export default options
