import {get} from "./request"
import {Store} from "vuex"
import {RootState} from "./types"
import {convert as convertParticipant} from "./participant/conversion"
import {convert as convertCommunication} from "./communication/conversion"
import {convert as convertInsurance} from "./insurance/conversion"
import {participantURL, sessionTimeout} from "./settings"
import eventBus from "lib/vue/eventBus"

export default async (store: Store<RootState>) => {
	// await get(participantURL + "ping/") // Check live session.

	if (!store.state.timestamp || Date.now() - store.state.timestamp > sessionTimeout) {
		const response = await get(participantURL)()
		const body = await response.json()
		const participant = convertParticipant(body.data)
		const communication = convertCommunication(body.data)
		const insurance = convertInsurance(body.data)
		store.commit("participant/hydrate", participant)
		store.commit("communication/hydrate", communication)
		store.commit("insurance/hydrate", insurance)
		store.commit("timestamp")
	}

	eventBus.emit("loaded")
}
